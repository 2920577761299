<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>
    <v-card v-if="nutrient">
      <v-card-title class="display-1">
        {{nutrient.categoryId}}
      </v-card-title>
      <v-card-title class="display-2" v-text="nutrient.name"></v-card-title>

      <v-card-text v-if="nutrient.dailyIntake">
        <v-alert type="info"> Daily intake: {{nutrient.dailyIntake}} {{nutrient.unit}}</v-alert>
      </v-card-text>
      
      <v-card-text v-if="articles && articles.length">
        <ArticleGrid :items="articles" :title="'More on '+nutrient.name" />
      </v-card-text>

      <v-card-text class="black--text" v-if="nutrient.usage && nutrient.usage.topFoods && nutrient.usage.topFoods.length">
        <h3 class="headline">Ingredients with high value of {{nutrient.name}}.</h3>
        <p>These are the best ingredients to use in your recipes for high intakes of {{nutrient.name}}.</p>
        <IngredientGrid :items="nutrient.usage.topFoods" />
      </v-card-text>
      
      <v-card-text class="black--text" v-if="recipes && recipes.length">
        <h3 class="headline">Popular recipes high in {{nutrient.name}}</h3>
        <p>Here are some recipes high in {{nutrient.name}} (sorted from high to low).</p>
        <RecipeGrid :recipes="recipes" />
      </v-card-text>

      <v-card-text>
        <NutrientCopyright />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import RecipeGrid from "@/components/RecipeGrid.vue";
import ArticleGrid from "@/components/ArticleGrid.vue";
import IngredientGrid from "@/components/IngredientGrid.vue";
import NutrientCopyright from "@/components/NutrientCopyright.vue";
import nutrientService from "@/services/nutrientService";
import articleService from "@/services/articleService";
import { EventBus } from '@/plugins/eventbus.js';

export default {
  name: "Nutrient",
  components: {
    RecipeGrid,
    IngredientGrid,
    NutrientCopyright,
    ArticleGrid,
  },
  props: {
  },
  data() {
    return {
      nutrient: null,
      articles: null,
      recipes: null
    };
  },
  async mounted() {
    this.loadData();
    this.articles = await articleService.listByNutrient(this.$route.params.id);
  },
  computed: {
    breadcrumbItems() {
      return this.nutrient == null ? [] : [
        { text: 'Nutrients', href: '/nutrients' },
        { text: this.nutrient.category.name, to: {name: 'nutrientCategory', params: {id: this.nutrient.category.id}} },
        { text: this.nutrient.name, disabled: true },
      ]
    },
  },
  methods: {
    loadData() {
      nutrientService.get(this.$route.params.id).then(response => {
        this.nutrient = response.data;
        var meta = {
          title: this.nutrient.name,
          description: null,
          image: null,
        };
        EventBus.$emit('page-header-change', meta);

      }).catch(() => {
        this.nutrient = null;
      });
 
      nutrientService.getRecipes(this.$route.params.id).then(response => {
        this.recipes = response.data.data;
      }).catch(() => {
        this.recipes = null;
      });
    }
  },
  
};
</script>

<style scoped>
</style>
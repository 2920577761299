<template>
  <div>
    <v-row dense>
      <v-col
        v-for="recipe in recipes"
        :key="recipe.o ? recipe.o.id : recipe.id"
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <RecipeCard :recipe="recipe"></RecipeCard>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import RecipeCard from "@/components/RecipeCard.vue";

export default {
  name: "RecipeGrid",
  components: {
    RecipeCard
  },
  props: {
    recipes: Array
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
  }
};
</script>

<template>
    <v-card
      :to="{name: 'recipe', params: {id: data.id}}"
      >
      <div class="orange pa-1 text-center white--text" v-if="data.notPublished">NOT PUBLISHED</div>
      <v-img
        :src="data.imgUrl"
        class="white--text align-end "
        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
        height="200px"
        :alt="data.name"
      >
        <v-card-title v-text="data.name" class=""></v-card-title>
      </v-img>
      <v-card-text>

        <div class="text--primary">
          <div>
            <v-chip title="This recipe appears to contain meat or fish and is not vegan!" color="red" dark v-if="data.origin && (data.origin == 'meat' || data.origin == 'fish')">NOT 100% VEGAN</v-chip>
            <v-chip title="This recipe appears to be vegetarian and not vegan!" color="orange" dark v-if="data.origin && data.origin == 'animal-produced'">NOT 100% VEGAN</v-chip>
            <v-chip title="Total approx content of the selected nutrient in this recipe (per person)" color="primary" v-if="score">{{score}}</v-chip>
            <v-chip title="The original source of this recipe" outlined v-if="data.source && $store.state.sourcesInfo[data.source]">{{$store.state.sourcesInfo[data.source].name}}</v-chip>
            <v-chip title="Total preparation time" outlined v-if="data.totalTime"><!-- <v-icon left color="gray">fa fa-clock</v-icon> --> {{data.totalTime | duration }}</v-chip>
            <v-chip title="Total calories in this recipe (per person)" outlined v-if="data.calories"><!-- <v-icon left color="gray">fa fa-bolt</v-icon> -->{{data.calories }} kcal</v-chip>
          </div>
          <!-- <p class="text-truncate recipe-description">{{ data.description | striphtml }}</p> -->
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          text
          color="primary"
        >
          View
        </v-btn>
        <v-spacer></v-spacer>
        <div v-if="data.avgRating && data.avgRating.count > 0" class="mb-0">
          <v-rating
            v-model="data.avgRating.value"
            half-increments
            readonly
            dense
            aria-label="Rating of this recipe"
            size=14
            full-icon="fas fa-star"
            half-icon="fas fa-star-half-alt"
            empty-icon="far fa-star"
            color="orange"
            background-color="yellow"
            class="d-inline"
          ></v-rating>({{data.avgRating.count}})
        </div>
      </v-card-actions>
    </v-card>
</template>

<script>

export default {
  name: "RecipeCard",
  props: {
    recipe: Object
  },
  data: function() {
    return {
      data: null,
      score: null
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
  watch: {
    recipe: {
      // the callback will be called immediately after the start of the observation
      immediate: true, 
      handler (val, oldVal) {
        //console.log('Prop changed: ', val, ' | was: ', oldVal)
        if (val.o) {
          // scored list
          this.data = val.o;
          this.score = val.d || val.s;
        }
        else {
          this.data = val;
        }
      }
    }
  }
};
</script>

<style scoped>
	.recipe-description {
	}
</style>